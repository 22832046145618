<template>
  <ConfirmDialog></ConfirmDialog>
  <div class="align-items-center card flex grid">
    <div class="grid col-12">
      <div class="col-6">
        <table
          class="table table-bordered"
          style="width: 100%; font-size: 14px"
        >
          <tr>
            <td class="text-right">
              <strong>CAJA # : </strong>
            </td>
            <td class="text-left">{{ caja.id }}</td>
          </tr>
          <tr>
            <td class="text-right">
              <strong>ESTADO : </strong>
            </td>
            <td class="text-left">
              <span
                :class="
                  'border-round-2xl customer-badge p-2 ' +
                  claseEstado[caja.estado]
                "
              >
                <i
                  style="font-size: 1.3rem"
                  :class="
                    this.caja.estado == 1
                      ? 'font-semibold pi pi-lock-open'
                      : 'font-semibold pi pi-lock'
                  "
                ></i>
                {{ this.caja.estado_texto }}
              </span>
            </td>
          </tr>
          <tr>
            <td class="text-right">
              <strong>USUARIO : </strong>
            </td>
            <td class="text-left">{{ caja.nombre_user }}</td>
          </tr>
          <tr>
            <td class="text-right">
              <strong>SUCURSAL : </strong>
            </td>
            <td class="text-left">{{ caja.nombre_sucursal }}</td>
          </tr>
          <tr>
            <td class="text-right">
              <strong>F. APERTURA : </strong>
            </td>
            <td class="text-left">{{ caja.fecha_apertura }}</td>
          </tr>
          <tr>
            <td class="text-right">
              <strong>F. CIERRE : </strong>
            </td>
            <td class="text-left">
              {{ caja.fecha_cierre ?? "No definido" }}
            </td>
          </tr>
          <tr>
            <td class="text-right">
              <strong>SALDO INICIAL : </strong>
            </td>
            <td class="text-left">{{ caja.saldo_inicial }} Bs</td>
          </tr>
        </table>
      </div>
      <div class="col-6">
        <table
          class="table table-bordered"
          style="width: 100%; font-size: 14px"
        >
          <tr>
            <td class="text-right">
              <strong>TOTAL VENTAS : </strong>
            </td>
            <td class="text-left">{{ caja.calculo_total_ventas }} Bs</td>
          </tr>
          <tr>
            <td class="text-right">
              <strong>TOTAL EFECTIVO : </strong>
            </td>
            <td class="text-left">
              {{ convertirNumeroGermanicFormat(caja.calculo_total_saldo_caja) }}
              Bs
            </td>
          </tr>
          <tr>
            <td class="text-right">
              <strong>TOTAL QR : </strong>
            </td>
            <td class="text-left">
              {{ convertirNumeroGermanicFormat(total_caja_qr ?? 0) }}
              Bs
            </td>
          </tr>
          <tr>
            <td class="text-right">
              <strong>TOTAL TARJETA : </strong>
            </td>
            <td class="text-left">
              {{ convertirNumeroGermanicFormat(total_caja_tarjeta ?? 0) }}
              Bs
            </td>
          </tr>
          <tr v-show="false">
            <td class="text-right">
              <strong>TOTAL M.PAGOS : </strong>
            </td>
            <td class="text-left">
              {{
                convertirNumeroGermanicFormat(
                  caja.calculo_total_ventas_metodos_pago
                )
              }}
              Bs
            </td>
          </tr>
          <tr v-show="false">
            <td class="text-right">
              <strong>TOTAL GENERAL COBRADO : </strong>
            </td>
            <td class="text-left">
              {{
                convertirNumeroGermanicFormat(
                  caja.calculo_total_efectivo_transferencia
                )
              }}
              Bs
            </td>
          </tr>
          <tr>
            <td class="text-right">
              <strong>SALDO SEGÚN REGISTRO : </strong>
            </td>
            <td class="text-left">
              {{ convertirNumeroGermanicFormat(caja.recuento_efectivo ?? 0) }}
              Bs
            </td>
          </tr>
          <!-- <tr>
            <td class="text-right">
              <strong>DIFERENCIA : </strong>
            </td>
            <td
              class="text-left"
              :style="
                caja.diferencia < 0
                  ? 'color: red; font-weight: bold'
                  : 'color: green; font-weight: bold'
              "
            >
              {{ caja.diferencia }} Bs
            </td>
          </tr> -->
          <tr>
            <td class="text-right">
              <strong>DIFERENCIA : </strong>
            </td>
            <td
              class="text-left"
              :style="
                caja.recuento_efectivo - caja.calculo_total_saldo_caja < 0
                  ? 'color: red; font-weight: bold'
                  : 'color: green; font-weight: bold'
              "
            >
              {{ caja.recuento_efectivo - caja.calculo_total_saldo_caja }} Bs
            </td>
          </tr>
          <!--  <tr>
            <td class="text-right">
              <strong>SALDO CAJA : </strong>
            </td>
            <td class="text-left">{{ caja.saldo_caja }} Bs</td>
          </tr> -->
          <tr>
            <td class="text-right">
              <strong>SALDO CAJA : </strong>
            </td>
            <td class="text-left">{{ caja.calculo_total_saldo_caja }} Bs</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="grid col-12">
      <label for="observacion"
        ><strong>OBSERVACI&Oacute;N : </strong>{{ caja.observacion }}</label
      >
    </div>
  </div>
  <div class="grid" v-if="caja.mov_cajas && caja.mov_cajas.length > 0">
    <div class="card col-12">
      <h5><strong>MOVIMIENTOS DE CAJA:</strong></h5>
      <DataTable
        ref="dtmovcajas"
        :value="caja.mov_cajas"
        dataKey="id"
        class="p-datatable-sm"
        stripedRows
        showGridlines
      >
        <Column>
          <template #header>
            <i class="pi pi-cog text-xl ml-2" />
          </template>
          <template #body="slotProps">
            <Button
              icon="pi pi-print"
              class="p-button-icon-only p-button-raised"
              v-tooltip.top="'Imprimir Recibo'"
              @click="imprimirMovimientos(slotProps.data.id)"
            ></Button>
          </template>
        </Column>
        <Column field="id" header="ID"></Column>
        <Column field="fecha_mov" header="FECHA MOVIMIENTO"></Column>
        <Column field="tipo_mov_texto" header="TIPO MOVIMIENTO"></Column>
        <Column
          field="concepto"
          header="CONCEPTO/MOTIVO"
          style="font-weight: bold"
        ></Column>
        <Column field="autorizado_por" header="AUTORIZADO POR"></Column>
        <Column field="importe" header="EFCTIVO" style="text-align: right">
          <template #body="data">
            {{ data.data.importe }}
          </template>
        </Column>
        <Column
          field="importe_metodos_pagos"
          header="TRANSFERENCIAS"
          style="text-align: right"
        >
          <template #body="data">
            {{ data.data.importe_metodos_pagos }}
            {{
              data.data.metodo_pago_id != 1 ? data.data.nombre_metodo_pago : ""
            }}
          </template>
        </Column>
        <ColumnGroup type="footer">
          <Row>
            <Column
              footer="TOTALES:"
              :colspan="6"
              footerStyle="text-align:right"
            />
            <Column class="text-right" :footer="total_efectivo" />
            <Column class="text-right" :footer="total_transferencia" />
          </Row>
        </ColumnGroup>
      </DataTable>
    </div>
  </div>
  <div class="grid">
    <div class="card col-12">
      <h5><strong>MOVIMIENTOS DE VENTAS:</strong></h5>
      <DataTable
        ref="detalle"
        :value="caja.ventas"
        dataKey="id"
        class="p-datatable-sm"
        stripedRows
        showGridlines
      >
        <Column>
          <template #header>
            <i class="pi pi-cog text-xl ml-2" />
          </template>
          <template #body="slotProps">
            <Button
              icon="pi pi-ellipsis-v"
              class="p-button-icon-only p-button-raised"
              v-tooltip.top="'Ver Acciones'"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            ></Button>
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            ></Menu>
          </template>
        </Column>
        <Column class="text-right" field="id" header="COD"></Column>
        <Column field="nombre_cliente" header="CLIENTE"></Column>
        <Column field="fecha_venta" header="FECHA VENTA"></Column>
        <Column class="text-right" field="total_cancelar" header="SUBTOTAL">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                parseFloat(slotProps.data.total_cancelar) +
                  parseFloat(slotProps.data.descuento)
              )
            }}
          </template>
        </Column>
        <Column field="descuento" header="DESCUENTO" class="text-right">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(slotProps.data.descuento) + " Bs"
            }}
          </template>
        </Column>
        <Column field="total_cancelar" header="TOTAL VENTA" class="text-right">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(slotProps.data.total_cancelar) +
              " Bs"
            }}
          </template>
        </Column>
        <Column field="calculo_efectivo" header="EFECT." class="text-right">
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.calculo_efectivo) }}
          </template>
        </Column>
        <Column field="total_venta_qr" header="QR" class="text-right">
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.total_venta_qr) }}
          </template>
        </Column>
        <Column field="total_venta_tarjeta" header="TARJETA" class="text-right">
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.total_venta_tarjeta) }}
          </template>
        </Column>
        <Column
          class="text-right"
          field="monto_interes_total"
          header="INTERES"
          v-if="'R_Ventas de VentasCredito' in auth.user.permissions"
        >
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.monto_interes_total
              ) + " Bs"
            }}
          </template>
        </Column>
        <Column
          class="text-right"
          field="total_venta"
          header="TOTAL COBRADO"
          v-if="'R_Ventas de VentasCredito' in auth.user.permissions"
        >
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                totalCobrado(
                  slotProps.data.total_cancelar,
                  slotProps.data.monto_interes_total
                )
              ) + " Bs"
            }}
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado2[data.estado]">
              {{ data.estado_texto }}
            </span>
          </template>
        </Column>
        <ColumnGroup type="footer">
          <Row>
            <Column
              footer="TOTAL VENTAS:"
              :colspan="6"
              footerStyle="text-align:right"
            />
            <Column
              class="text-right"
              :footer="convertirNumeroGermanicFormat(caja.calculo_total_ventas)"
            />
          </Row>
        </ColumnGroup>
        <!-- <template #footer>
          <div class="text-right font-bold">
            TOTAL VENTAS :
            {{
              convertirNumeroGermanicFormat(caja.calculo_total_ventas) + " Bs"
            }}
          </div>
        </template> -->
      </DataTable>
      <div class="grid mt-2 justify-content-end">
        <Button @click="goBack">
          <i class="pi pi-arrow-circle-left mr-1"></i>
          &nbsp;Atr&aacute;s
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import CajaService from "@/service/CajaService";
import VentaService from "@/service/VentaService";
import { useAuth } from "@/stores";
export default {
  data() {
    return {
      caja: {},
      caja_id: this.$route.params.id,
      claseEstado: ["bg-", "bg-green-100", " bg-red-100"],
      claseEstado2: ["status-outofstock", "status-instock", "status-lowstock"],
      generando_pdf: false,
      medidors: [],
      total_caja_qr: 0,
      total_caja_tarjeta: 0,
    };
  },
  cajaService: null,
  ventaService: null,
  auth: null,
  created() {
    this.cajaService = new CajaService();
    this.ventaService = new VentaService();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarCaja();
  },
  computed: {
    total_efectivo() {
      return this.convertirNumeroGermanicFormat(this.total_importe);
    },
    total_transferencia() {
      return this.convertirNumeroGermanicFormat(
        this.total_importe_metodos_pagos
      );
    },
  },
  methods: {
    imprimirMovimientos(id) {
      this.generando_pdf = true;
      this.cajaService.imprimirMovimientosPDF(id).then(() => {
        this.generando_pdf = false;
      });
    },
    acciones(datos) {
      return [
        {
          label: "Ver Venta",
          icon: "pi pi-fw pi-eye",
          disabled:
            "Venta Ver Detalle" in this.auth.user.permissions ? false : true,
          command: () => {
            this.detalleVenta(datos.id);
          },
        },
        /* {
          label: "Anular Venta",
          visible: "Venta Anular" in this.auth.user.permissions ? false : true,
          icon: "pi pi-fw pi-trash",
          disabled:
            datos.estado == 0
              ? true
              : false || "Venta Anular" in this.auth.user.permissions
              ? false
              : true,
          command: () => this.anularVenta(datos.id),
        }, */
      ];
    },
    totalCobrado(total, interes) {
      return parseFloat(total) + parseFloat(interes);
    },
    anularVenta(id) {
      this.$confirm.require({
        message: "¿Está seguro que desea anular la venta?",
        header: "Anular Venta",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, deseo Anular",
        rejectLabel: "No, Cancelar",
        accept: () => {
          this.ventaService.anularVenta(id).then((response) => {
            this.$toast.add({
              severity: "success",
              summary: "Venta Anulada",
              detail: response.mensaje,
              life: 3000,
            });
            this.cargarCaja();
          });
        },
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    detalleVenta(id) {
      this.$router.push({ name: "venta_detalle", params: { id: id } });
    },
    cargarCaja() {
      this.cajaService
        .getCaja(this.caja_id)
        .then((response) => {
          this.caja = response.caja;
          this.medidors = response.medidors;
          //recorro mov_cajas para sumar los importes de los importe_metodos_pagos y importe
          let mov_cajas = this.caja.mov_cajas ?? [];
          let total_importe_metodos_pagos = 0;
          let total_importe = 0;
          for (let i = 0; i < mov_cajas.length; i++) {
            total_importe_metodos_pagos += parseFloat(
              mov_cajas[i].importe_metodos_pagos
            );
            total_importe += parseFloat(mov_cajas[i].importe);
          }
          this.total_importe_metodos_pagos = total_importe_metodos_pagos;
          this.total_importe = total_importe;

          //recorro detalle_suma_cobros_x_metodos_pago para sumar los importes de los importe_metodos_pagos y importe
          if (this.caja.detalle_suma_cobros_x_metodos_pago) {
            let detalle_suma_cobros_x_metodos_pago =
              this.caja.detalle_suma_cobros_x_metodos_pago;

            // Inicializar variables
            this.total_caja_qr = 0;
            this.total_caja_tarjeta = 0;

            for (
              let i = 0;
              i < detalle_suma_cobros_x_metodos_pago.length;
              i++
            ) {
              if (detalle_suma_cobros_x_metodos_pago[i].metodo_pago_id == 6) {
                // qr
                this.total_caja_qr = parseFloat(
                  detalle_suma_cobros_x_metodos_pago[i].total
                );
              }
              if (detalle_suma_cobros_x_metodos_pago[i].metodo_pago_id == 2) {
                // tarjeta
                this.total_caja_tarjeta = parseFloat(
                  detalle_suma_cobros_x_metodos_pago[i].total
                );
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
